h1, p, a, .console {font-family: 'Source Code Pro', monospace;}
p {font-weight:400;}
h1{font-weight:400;}
h2{font-family:'Source Code Pro', monospace; font-weight: 400;font-size:max(1.25vw, 1.5em);margin-bottom:0rem;}

.main-wrap{
  width:100%;
  margin-top:3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}
.intro-wrap{width:min(576px, 40%)}
.intro-header{
  display:flex;
  align-items:center;
  justify-content: space-between;
}
.console{
  max-width:100%;
  background-color: #212121;
  color:white;
  padding:2.5rem;
  box-sizing: border-box;
}

.nav{
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
.nav .square{
  width: 5px;
  height:5px;
  background: #82B1FF;
  margin-top:.2rem;
  border-radius: 1px;
}
.nav .icon {
  color:#212121;
  width:24px; 
  height:24px;
  margin-left:8px;
}

.nav .icon:hover{
  opacity:.85;
  cursor: pointer;
}

.console-marker{
  padding-right:1rem;
}
.command-line{
  display:flex;
}
.bio p{
  margin:0;
}
.bio{
  margin-top:.75rem;
  line-height:1.5;
  padding-left:1.5rem;
}

.projects-wrap{
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.projects-wrap-title-box{
  width:100%;
}
.projects-wrap-title-box h2{
  text-align:start;
}


.projects-subwrap{
  width:min(576px, 40%);
}
.projects-grid{
  display:grid;
  grid-template-columns: 1fr;
  grid-gap:1rem;
  margin: 2rem 0;
}
.project-card{
  width:100%;
  height:7.5em;
  display:flex;
}

.project-card .img-wrap{
  border-radius:10px 0 0 10px;
  height:100%;
  width:33%;
}

.img-wrap img {
  object-fit:cover;
  width:100%;
  height:100%;
}

.project-card .info{
  flex:1;
  padding:0;
  padding-left:1.5rem;
}

.project-card .info h3{
  font-family: 'Source Code Pro', monospace;
  font-size:max(.85vw, 1em);
  font-weight:500;
  margin:0;
}

.project-card .info p{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:300;
  margin:.5rem 0;
  color:#565656;
}

.tag-wrap {
  margin-top:1rem;
  display:flex;
}

.project-card .info .tag-wrap .tag {
  border:1px solid #E0E0E0;
  font-size:.75em;
  color:#565656;
  border-radius:5px;
  margin-right:.5rem;
  padding:.25rem 1rem;
}

.project-card a {
  color:black;
}

.project-card a:hover{
  color:blue;
}

.footer{
  width:100%;
  background-color:#212121;
  display:flex;
  padding: 1rem 30vw;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

.footer h4{
  color:white;
  font-family: "Source Sans Pro", monospace;
  font-size:1.5em;
  font-weight: 400;
  margin:0;
}
.footer p{
  font-family: "Source Sans Pro", monospace;
  color:white;
  margin:0;
  font-weight: 200;
}
.footer .icons {
  display:flex;
}

.footer .icon {
  color:white;
  font-size:28px;
  width:28px; 
  height:28px;
  margin-right:12px;
  padding:.5rem 0;
  opacity:.8;
  transition: color 1s ease-in-out;
  cursor: pointer;
}
.footer .icon:hover{
  opacity:1;
}


/** 6-1-21 **/
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding: 5rem;
  width:40%;
}

.intro-header{
  width: 100%;
  display:flex;
  align-items:center;
  justify-content: space-between;
}

.intro{
  margin-bottom:4rem;
}

/**MEDIA QUERIES**/
@media screen and (max-device-width: 480px) and (orientation: portrait){
  .main-wrap{
    height:auto;
  }

  .content {
    padding: 1rem;
    width:90%;
  }
  .intro-wrap{
    width:90%;
  }
  .intro-header{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin:2rem 0;
  }
  .intro-header h1{
    margin:1rem 0;
  }
  .console{
    padding:2rem 1rem;
  }

  .project-card{
    height:auto;
  }
  .project-card .info{
    padding:1rem 0;
  }
  .project-card .img-wrap{
    width:100%;
  }
  .project-card{
    flex-direction: column;
    border-bottom:1px solid #EEEEEE;
  }
  .projects-grid{
    grid-gap:2rem;
  }
  .img-wrap img{
    width:100%;
    height:12em;
  }
  .nav .icon {
    color:#212121;
    width:32px; 
    height:32px;
    margin-right:8px;
    margin-left:0;
  }


}
